export async function setup3dsDropin(
  enablePaypal = false,
  container = "#dropin-container",
) {
  // if (window.dropinInstance) await window.dropinInstance.teardown();

  console.log("3DS Dropin: Instantiating...");
  var response = await fetch("/api/braintree-utils/signup-client-token/");
  if (!response) {
    console.log("Failed to retrieve clientToken from server: ", response);
    BatchGeo.blockAlert(
      "An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.",
      () => window.location.reload(true),
    );
  } else {
    response = await response.json();
  }

  $(container).empty();
  const clientToken = response.clientToken;

  const dropInConfig = {
    defaultFirst: true,
    authorization: clientToken,
    container: container,
  };

  if (enablePaypal) {
    dropInConfig.paypal = {
      flow: "vault",
    };
  }

  try {
    window.dropinInstance = await braintree.dropin.create(dropInConfig);
    console.log("3DS instantiated");
  } catch (e) {
    console.error(
      "Failed to instantiate 3DS Drop-in. Double check your clientToken",
      e,
    );
    BatchGeo.blockAlert(
      "An error occured. Please reload the page and try again. If this issue persists, please contact Batchgeo support.",
      () => window.location.reload(true),
    );
  }
}
window.setup3dsDropin = setup3dsDropin;

export function handleVerifyCardWith3DSecureError(
  error,
  logMessage = "",
  onError,
) {
  console.error(logMessage, error);
  if (onError) onError(logMessage, error);
}
window.handleVerifyCardWith3DSecureError = handleVerifyCardWith3DSecureError;

export async function verifyCardWith3DSecure(
  threeDSecureInfo,
  email,
  postalCode,
  planCost,
  // eslint-disable-next-line no-unused-vars
  onError = (logMessage, message) => {},
) {
  let { nonce, customerId, clientToken, planId, bin } = threeDSecureInfo;

  if (!nonce || !customerId || !clientToken || !planId || !bin) {
    console.error("Cannot verify card without threeDSecureInfo");
  }

  const threeDSecureParameters = {
    amount: planCost || "0",
    // To test error handling, comment out nonce
    nonce,
    bin,
    onLookupComplete: function (data, next) {
      next();
    },
    email,
    billingAddress: {
      postalCode: postalCode,
    },
  };

  var btClient = await braintree.client
    .create({
      // Use the generated client token to instantiate the Braintree client.
      authorization: clientToken,
    })
    .catch(function (error) {
      console.log(threeDSecureInfo);
      handleVerifyCardWith3DSecureError(
        error,
        "Error creating braintree client",
        onError,
      );
      return false;
    });

  var bt3ds = await braintree.threeDSecure
    .create({
      version: 2, // Will use 3DS2 whenever possible
      client: btClient,
    })
    .catch(function (error) {
      handleVerifyCardWith3DSecureError(
        error,
        "Error creating braintree client",
        onError,
      );
      return false;
    });

  var nonceVerfied = await bt3ds
    .verifyCard(threeDSecureParameters)
    .catch(function (error) {
      handleVerifyCardWith3DSecureError(
        error,
        "Error verifying user card",
        onError,
      );
      return false;
    });

  return nonceVerfied;
}
window.verifyCardWith3DSecure = verifyCardWith3DSecure;

//the card verification needs to be run from window due to the way braintree's library works,
// this function wraps it so we can import the processes without needing to guess what part of the window it's on
// async function verifyCardWith3DSecure(
//   threeDSecureInfo,
//   email,
//   postalCode,
//   planCost,
// ) {
//   if (!window.verifyCardWith3DSecure)
//     window.verifyCardWith3DSecure = windowVerifyCardWith3DSecure;
//   return await window.verifyCardWith3DSecure(
//     threeDSecureInfo,
//     email,
//     postalCode,
//     planCost,
//   );
//   //clear window?
// }

export async function reverifyUserCard(planId, addonId, addonCount) {
  //get estimated cost
  var estimatedPlanCost = await fetch(
    `../../api/braintree-utils/get-plan-pricing/?plandId=${planId}&addonType=${addonId}&addonCount=${addonCount}`,
  );
  if (!estimatedPlanCost.ok) {
    console.error("could not retrieve price data");
    return;
  }
  estimatedPlanCost = await estimatedPlanCost.json();

  //get user info
  var currentUserInfo = await fetch(
    "/api/braintree-utils/get-current-user-pricing/",
  );
  if (!currentUserInfo.ok) {
    console.error("Could not retreive user data");
    return;
  }
  currentUserInfo = await currentUserInfo.json();

  if (
    !currentUserInfo.threeDSecureInfo ||
    estimatedPlanCost.planCost <= currentUserInfo.planCost
  ) {
    // card is not 3ds enabled ||
    //not performing actions that need plan updates
    //don't verify.
    return;
  }

  //verify
  var { threeDSecureInfo, email, postalCode, planCost } = currentUserInfo;
  var verify = await verifyCardWith3DSecure(
    threeDSecureInfo,
    email,
    postalCode,
    planCost,
  );
  return verify;
}
window.reverifyUserCard = reverifyUserCard;

// A template for if we need to wait for user input on 3ds verfication
//
// async function open3dsModal() {
//   var modal = $("#3ds-verification-modal");
//   if (!modal) console.error("Could not find 3ds verification modal");
//   modal.show();
//   await setup3dsDropin(false, "#modal-dropin-container");
//   var result = Promise.withResolvers();
//   $("#verification-submit").click(async (e) => {
//     e.preventDefault();
//     result.resolve(await reverifyUserCard());
//   });
//   return await result.promise;
// }

// eslint-disable-next-line no-undef
// module.exports = {
//   setup3dsDropin: setup3dsDropin,
//   verifyCardWith3DSecure: verifyCardWith3DSecure,
//   handleVerifyCardWith3DSecureError: handleVerifyCardWith3DSecureError,
//   // open3dsModal: open3dsModal,
//   reverifyUserCard: reverifyUserCard,
// };
